/**
 * @license
 * Copyright 2021 Google LLC. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */

//import html_logo from '/models/DESERT/DESERT_VR.gltf'

import '@tensorflow/tfjs-backend-webgl';
import '@tensorflow/tfjs-backend-webgpu';

import * as mpPose from '@mediapipe/pose';
import * as tfjsWasm from '@tensorflow/tfjs-backend-wasm';
import * as tf from '@tensorflow/tfjs-core';

tfjsWasm.setWasmPaths(
    `https://cdn.jsdelivr.net/npm/@tensorflow/tfjs-backend-wasm@${
        tfjsWasm.version_wasm}/dist/`);

import * as posedetection from '@tensorflow-models/pose-detection';

import {Camera} from './camera';
import {RendererWebGPU} from './renderer_webgpu';
import {RendererCanvas2d} from './renderer_canvas2d';
import {setupDatGui} from './option_panel';
import {STATE} from './params';
import {setupStats} from './stats_panel';
import {setBackendAndEnvFlags} from './util';

let detector, camera, stats;
let startInferenceTime, numInferences = 0;
let inferenceTimeSum = 0, lastPanelUpdate = 0;
let rafId;
let renderer = null;
let useGpuRenderer = false;
let initZoomForm = 0;

import * as THREE from 'three';

			import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
			import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
			import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';

			let defaultCamera,activeCamera, scene, renderer3D, sizeObj, controls, prevTime;
			let p_width, p_height;
			let windowHalfX,windowHalfY;
			let targetX = 0;
			let targetY = 0;
			let mouseX = 0;
			let mouseY = 0;
			let mesh;
			let nbItem = 2;
			let Titem = new Array();
			let isLoadScene = false;
			

async function createDetector() {
  switch (STATE.model) {
    case posedetection.SupportedModels.PoseNet:
      return posedetection.createDetector(STATE.model, {
        quantBytes: 4,
        architecture: 'MobileNetV1',
        outputStride: 16,
        inputResolution: {width: 640, height: 480},
        multiplier: 1
      });
    case posedetection.SupportedModels.BlazePose:
      const runtime = STATE.backend.split('-')[0];
      if (runtime === 'mediapipe') {
        return posedetection.createDetector(STATE.model, {
          runtime,
          modelType: STATE.modelConfig.type,
          solutionPath:
              `https://cdn.jsdelivr.net/npm/@mediapipe/pose@${mpPose.VERSION}`
        });
      } else if (runtime === 'tfjs') {
        return posedetection.createDetector(
            STATE.model, {runtime, modelType: STATE.modelConfig.type});
      }
    case posedetection.SupportedModels.MoveNet:
      let modelType;
      if (STATE.modelConfig.type == 'lightning') {
        modelType = posedetection.movenet.modelType.SINGLEPOSE_LIGHTNING;
      } else if (STATE.modelConfig.type == 'thunder') {
        modelType = posedetection.movenet.modelType.SINGLEPOSE_THUNDER;
      } else if (STATE.modelConfig.type == 'multipose') {
        modelType = posedetection.movenet.modelType.MULTIPOSE_LIGHTNING;
      }
      
     // modelType = posedetection.movenet.modelType.MULTIPOSE_LIGHTNING;
      const modelConfig = {modelType};

      if (STATE.modelConfig.customModel !== '') {
        modelConfig.modelUrl = STATE.modelConfig.customModel;
      }
      if (STATE.modelConfig.type === 'multipose') {
        modelConfig.enableTracking = STATE.modelConfig.enableTracking;
      }
      return posedetection.createDetector(STATE.model, modelConfig);
  }
}

async function checkGuiUpdate() {
  if (STATE.isTargetFPSChanged || STATE.isSizeOptionChanged) {
    camera = await Camera.setupCamera(STATE.camera);
    STATE.isTargetFPSChanged = false;
    STATE.isSizeOptionChanged = false;
  }

  if (STATE.isModelChanged || STATE.isFlagChanged || STATE.isBackendChanged) {
    STATE.isModelChanged = true;

    window.cancelAnimationFrame(rafId);

    if (detector != null) {
      detector.dispose();
    }

    if (STATE.isFlagChanged || STATE.isBackendChanged) {
      await setBackendAndEnvFlags(STATE.flags, STATE.backend);
    }

    try {
      detector = await createDetector(STATE.model);
    } catch (error) {
      detector = null;
      alert(error);
    }

    STATE.isFlagChanged = false;
    STATE.isBackendChanged = false;
    STATE.isModelChanged = false;
  }
}

function beginEstimatePosesStats() {
  startInferenceTime = (performance || Date).now();
}

function endEstimatePosesStats() {
  const endInferenceTime = (performance || Date).now();
  inferenceTimeSum += endInferenceTime - startInferenceTime;
  ++numInferences;

  const panelUpdateMilliseconds = 1000;
  if (endInferenceTime - lastPanelUpdate >= panelUpdateMilliseconds) {
    const averageInferenceTime = inferenceTimeSum / numInferences;
    inferenceTimeSum = 0;
    numInferences = 0;
    stats.customFpsPanel.update(
        1000.0 / averageInferenceTime, 120 /* maxValue */);
    lastPanelUpdate = endInferenceTime;
  }
}

async function renderResult() {
  if (camera.video.readyState < 2) {
    await new Promise((resolve) => {
      camera.video.onloadeddata = () => {
        resolve(video);
      };
    });
  }
  let poses = null;
  let canvasInfo = null;
  // Detector can be null if initialization failed (for example when loading
  // from a URL that does not exist).
  if (detector != null) {
    // FPS only counts the time it takes to finish estimatePoses.
    beginEstimatePosesStats();

    if (useGpuRenderer && STATE.model !== 'PoseNet') {
      throw new Error('Only PoseNet supports GPU renderer!');
    }
    // Detectors can throw errors, for example when using custom URLs that
    // contain a model that doesn't provide the expected output.
    try {
      if (useGpuRenderer) {
        const [posesTemp, canvasInfoTemp] = await detector.estimatePosesGPU(
            camera.video,
            {maxPoses: STATE.modelConfig.maxPoses, flipHorizontal: false},
            true);
        poses = posesTemp;
        canvasInfo = canvasInfoTemp;
      } else {
	      
        poses = await detector.estimatePoses(
            camera.video,
            {maxPoses: STATE.modelConfig.maxPoses, flipHorizontal: false});
            if (typeof poses === 'object' && poses !== null) {
	           await checkItem(poses)
	           await resetNez(poses.length);
	             document.querySelector('#status').innerHTML =  poses.length + ' / ' + STATE.modelConfig.maxPoses;
	            for (var cmp=0;cmp<poses.length;cmp++) {
		            console.log("POSE : " + poses[cmp])
		            //
		            var pose = poses[cmp]
		           const leftEye = pose.keypoints.find((keypoint) => keypoint.name === 'left_eye');
			        const rightEye = pose.keypoints.find((keypoint) => keypoint.name == 'right_eye');
			        const leftMouth = pose.keypoints.find((keypoint) => keypoint.name === 'mouth_left');
			        const rightMouth = pose.keypoints.find((keypoint) => keypoint.name === 'mouth_right');
			        const nose = pose.keypoints.find((keypoint) => keypoint.name === 'nose');
			        
			        
	      
		            //console.log("DISTANCE : " + d)
		            var posX = 0;
		            var posY = 0;
		            if (nose.x >= 320) posX = parseFloat(640 - nose.x);
		            else posX = parseFloat(640 - nose.x);
		            //var posX = parseFloat(nose.x - (640/2));
		            
		            
		            
		            posX = posX - (document.querySelector('.nose').offsetWidth / 2)
		            //var posZoomX = (0.00625*posX) - 1;
		            var posZoomX = posX;
		            
		            posY = parseFloat(nose.y);
		            
		            var distanceY = nose.y - leftEye.y;
		            var posZoomY = nose.y + distanceY;
		            //var posZoomY = (-0.00625*posY) + 1;
		            
		            
		            posY = posY - (document.querySelector('.nose').offsetHeight / 2)
		            
		            if (!isLoadScene) {
			            document.querySelector('#Nose_' + cmp).style.left=posX + "px";
						document.querySelector('#Nose_' + cmp).style.top=posY + "px";
		            }
		            else {
			            document.querySelector('#Nose_' + cmp).style.left=posX + "px";
						document.querySelector('#Nose_' + cmp).style.top="-1000px";
		            }
		            
		             
		            const d = parseFloat(Math.sqrt((leftEye.x - rightEye.x) ** 2, (leftEye.y - rightEye.y) ** 2))
		            
			        var zoomForm = parseFloat((d - 20) / 28.57);
			        //console.log("zoomForm : " + zoomForm)
			        //zoomForm = zoomForm.toFixed(2);
			        //console.log("zoomForm : " + zoomForm)
			        
			        //if ((zoomForm > initZoomForm + 0.08) || (zoomForm < initZoomForm - 0.08)) setZoom(1,zoomForm)
			        
			        initZoomForm = zoomForm;
			        
			        //moveElement(i,posZoomX,posZoomY)
			        
		            moveItem(cmp,posZoomX,posZoomY)
		            //object.scene.position.x = posX;
					//object.scene.position.z = posY;
		            
		            //controls.update();
		           
		            
			    //this.stats.update();
			    //this.mixer && this.mixer.update(dt);
			    
	            }
	        }
      }
    } catch (error) {
      /*detector.dispose();
      detector = null;
      alert(error);*/
    }

    endEstimatePosesStats();
  }
  const rendererParams = useGpuRenderer ?
      [camera.video, poses, canvasInfo, STATE.modelConfig.scoreThreshold] :
      [camera.video, poses, STATE.isModelChanged];
  renderer.draw(rendererParams);
}

async function renderPrediction() {
  await checkGuiUpdate();

  if (!STATE.isModelChanged) {
    await renderResult();
  }

  rafId = requestAnimationFrame(renderPrediction);
};

async function app() {
  // Gui content will change depending on which model is in the query string.
  const urlParams = new URLSearchParams(window.location.search);
  /*if (!urlParams.has('model')) {
    alert('Cannot find model in the query string.');
    return;
  }*/
  
  await setupDatGui(urlParams);

  stats = setupStats();
  const isWebGPU = STATE.backend === 'tfjs-webgpu';
  const importVideo = (urlParams.get('importVideo') === 'true') && isWebGPU;

  camera = await Camera.setup(STATE.camera);

  await setBackendAndEnvFlags(STATE.flags, STATE.backend);

  detector = await createDetector();
  const canvas = document.getElementById('output');
  canvas.width = camera.video.width;
  canvas.height = camera.video.height;
  useGpuRenderer = (urlParams.get('gpuRenderer') === 'true') && isWebGPU;
  if (useGpuRenderer) {
    renderer = new RendererWebGPU(canvas, importVideo);
  } else {
    renderer = new RendererCanvas2d(canvas);
  }

  renderPrediction();
  
  document.querySelector('#loading').innerHTML = '<span class="done">Loading camera</span><span>Loading 3D..</span>';
  
  init3D();
};

function init3D() {
	prevTime = 0;
	var zone = document.getElementById( "zone" );
	const container = document.createElement( 'div' );
	document.body.appendChild( container );
	
	p_width = window.innerWidth;
	p_height = window.innerHeight;
	
	p_width = zone.offsetWidth;
	p_height = zone.offsetHeight;
	
	windowHalfX = p_width / 2;
	windowHalfY = p_height / 2;
	
	//p_width = windowHalfX
	
	scene = new THREE.Scene();
	console.log("WIDTH : " + p_width)
	
	const color7 = new THREE.Color(0x191919);
				//scene.background = color7;
	

	var fov = 20;
	defaultCamera = new THREE.PerspectiveCamera( fov, p_width / p_height, 1, 10000 );

    activeCamera = defaultCamera;
    scene.add( defaultCamera );
	
	
	renderer3D = window.renderer = new THREE.WebGLRenderer({antialias: true, alpha:true, preserveDrawingBuffer: true});
    //renderer.useLegacyLights = false;
    //renderer.outputEncoding = sRGBEncoding;
    //renderer.setClearColor( 0xcccccc );
    renderer3D.setPixelRatio( window.devicePixelRatio );
    renderer3D.setSize( p_width, p_height );
    renderer3D.toneMapping = THREE.ACESFilmicToneMapping;
	renderer3D.toneMappingExposure = 1;
    
    controls = new OrbitControls( defaultCamera, renderer3D.domElement );
	controls.screenSpacePanning = false;
	
	var light = new THREE.HemisphereLight( 0xbbbbff, 0x444422 );
	//light.position.set( 0, 1, 0 );
	scene.add( light );
	
	container.appendChild( renderer3D.domElement );
	zone.appendChild( container );
	
	var manager = new THREE.LoadingManager();
	var element_loading = document.querySelector("#loading");
	manager.onStart = function ( url, itemsLoaded, itemsTotal ) {
		element_loading.innerHTML = '3D started loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.';
	};
	
	manager.onLoad = function ( ) {
		element_loading.innerHTML = 'Loading complete!';
	};
	
	manager.onProgress = function ( url, itemsLoaded, itemsTotal ) {
		element_loading.innerHTML = '3D loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.';
	};
	
	manager.onError = function ( url ) {
		element_loading.innerHTML = 'There was an error loading ' + url;
	};
	
	const loader = new GLTFLoader(manager);
	//alert(html_logo)
	//loadImage(html_logo, document.getElementById('foo'));
	loader.load('/models/DESERT/DESERT_VR.gltf', function ( object ) {
			//object.scene.updateMatrixWorld(); // donmccurdy/three-gltf-viewer#330
			
			/*object.traverse( function ( child ) {

		        if ( child instanceof THREE.Mesh ) {
		
		            let geometry = child.geometry;
		            let material = child.material;
		            let mesh = new THREE.Mesh(geometry, material);
		
		            scene.add(mesh);
		
		            const wireframeGeometry = new THREE.WireframeGeometry( geometry );
		            const wireframeMaterial = new THREE.LineBasicMaterial( { color: 0xff0000 } );
		            const wireframe = new THREE.LineSegments( wireframeGeometry, wireframeMaterial );
		            mesh.add( wireframe );
		
		        }
		
		    });*/

		    const box = new THREE.Box3().setFromObject(object.scene);
		    const size = box.getSize(new THREE.Vector3()).length();
		    
		    const size2 = box.getSize(new THREE.Vector3());
		    const center = box.getCenter(new THREE.Vector3());

		    controls.reset();
		    object.scene.position.x -= size2.x  + (size2.x / 2);
		    object.scene.position.y -= size2.y / 2;
		    object.scene.position.z += (object.scene.position.z - center.z);

		    renderer3D.setSize( p_width, p_height );
		    
		    
		    controls.maxDistance = size * 10;
		    defaultCamera.near = size / 100;
		    defaultCamera.far = size * 100;
		   
		    defaultCamera.position.x += size / 2.0 - 3;
		    defaultCamera.position.y += size / 2.0 + 3;
		    defaultCamera.position.z += size / 2.0 - 40;
		    //defaultCamera.zoom =1;
		    
		    //defaultCamera.position.set( 20, 20, 20 );
		    defaultCamera.updateProjectionMatrix();
		    
		    //defaultCamera.position.set( 20, 20, 20 );
		    //defaultCamera.lookAt(center);


		
			//var element = document.querySelector(".loading");
			//element.classList.add("hide")
			
			var obj = new Object()
			obj.id = 0;
			obj.element = object.scene;
			
			Titem.push(obj)
			
			
			
			/**/
		    
			scene.add( Titem[0].element );
			
			
			
			mesh = object.scene;
			
			
			
			
			
			
			
			//setZoom(2,0.5)
			
			
			
			
			
			console.log("mesh.rotation.x : " + object.scene.position.x)
			
			//defaultCamera.zoom = defaultCamera.zoom + 0.5;
			
			//createScene( object.scene, 100, material );
			
			
			//requestAnimationFrame( animate );
			
			document.querySelector('#loading').innerHTML = '<span class="done">Loading camera</span><span class="done">Loading 3D</span><span>Done</span>';
			render3D();
			
			isLoadScene = true;
			
			document.querySelector("#zone").classList.add("off")
		

	} );
	
	animate = animate.bind(this);
	requestAnimationFrame( animate );
			    
	window.addEventListener( 'resize', onWindowResize );
	window.addEventListener( 'keyup', logKey );
	//window.addEventListener( 'mousemove', onMouseMove, false );
}

var mouse = new THREE.Vector2();
var raycaster = new THREE.Raycaster();
var intersects = new THREE.Vector3();
var plane = new THREE.Plane(new THREE.Vector3(0, 1, 0), 0);

async function ResetItem() {
	if (Titem.length > 0) {
		for (var i=1;i<Titem.length;i++) {
			removeItem(Titem[i].id)
		}
	}
	
}

async function resetNez(val) {

		for (var ii=0;ii<5;ii++) {
			document.querySelector('#Nose_' + ii).style.left="0px";
		    document.querySelector('#Nose_' + ii).style.top="-1000px";
		}
	//}
	
}

async function checkItem(poses) {
	
	//if (items.length != Titem.length) {
	await ResetItem()
	
	if (poses.length > 1) {
		if (Titem.length > 0) {
			for (var ii=1;ii<poses.length;ii++) {
				var obj = new Object()
				obj.id = ii;
				obj.element = Titem[0].element.clone();
				Titem.push(obj)
				scene.add(obj.element);
			}
		}
	}
		
	//}
	
}
function removeItem(id)
{
	const objWithIdIndex = Titem.findIndex((obj) => obj.id === id);
console.log("objWithIdIndex : " + objWithIdIndex)
  if (objWithIdIndex > -1) {
	scene.remove(Titem[objWithIdIndex].element);
    Titem.splice(objWithIdIndex, 1);
  }

}

function moveItem(i,x,y) {
 mouse.x = ( x / p_width ) * 3;
	mouse.y = -( y / p_height ) * 3 + 1;
  raycaster.setFromCamera(mouse, defaultCamera);
  raycaster.ray.intersectPlane(plane, intersects);
  Titem[i].element.position.set(intersects.x, intersects.y, intersects.z);
}

function onMouseMove(e) {
	console.log("MOUSE X : " +event.clientX)
	console.log("MOUSE Y : " +event.clientY)
 mouse.x = ( event.clientX / p_width ) * 3;
	mouse.y = -( event.clientY / p_height ) * 3 + 1;
  raycaster.setFromCamera(mouse, defaultCamera);
  raycaster.ray.intersectPlane(plane, intersects);
  
  for (var i=0;i<Titem.length;i++) {
	  Titem[i].element.position.set(intersects.x+i, intersects.y+i, intersects.z);
  }
  
  
}




function moveElement(index,clientX,clientY)
{
	var target = document.querySelector('#zone');
	var xposition = (clientX - target.offsetWidth/2);
  var yposition = (clientY - target.offsetHeight/3);
  target.style.transform = "translate("+ xposition + "px," + yposition + "px)";
  console.log(event);

}



function onDocumentMouseMove( event ) {

    mouseX = ( event.clientX - windowHalfX );
    mouseY = ( event.clientY - windowHalfY );

}

function setZoom(sens,val){
    defaultCamera.zoom = val;
    defaultCamera.updateProjectionMatrix();
    console.log("ZOOMMMSET : " + val)
    render3D();
};

function logKey(e) {
  console.log("KEY ; " + e.code)
  
  if (e.code == 'ArrowRight') {
	  //removeItem(1) //setZoom(1,0.1)
  }
  
  if (e.code == 'ArrowLeft') {
	  //setZoom(2,0.1)
  }
}

function render3D() {
	renderer3D.render( scene, defaultCamera );

}

function animate(time) {
	requestAnimationFrame( animate );

    const dt = (time - prevTime) / 1000;

    controls.update();
    //this.stats.update();
    //this.mixer && this.mixer.update(dt);
    render3D();

    prevTime = time;
    
    /*if (isLoadScene) {
	    var target = new THREE.Vector3();
    
    
		target.x += ( mouseX - target.x ) * .02;
	    target.y += ( - mouseY - target.y ) * .02;
	    target.z = defaultCamera.position.z; // assuming the camera is located at ( 0, 0, z );
	    mesh.lookAt( target );
    }*/
    
    
    
    //console.log("X : " + defaultCamera.position.x )
    //console.log("Y : " + defaultCamera.position.y )
    //console.log("Z : " + defaultCamera.position.z )

}

function onWindowResize() {
	defaultCamera.aspect = p_width / p_height;
	defaultCamera.updateProjectionMatrix();

	renderer3D.setSize( p_width, p_height );

	render3D();

}

app();

if (useGpuRenderer) {
  renderer.dispose();
}
